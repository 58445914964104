// roboto font
@import 'variables';

@font-face {
  font-family: 'Roboto';
  src: local(Roboto Thin), url('#{$roboto-font-path}roboto-thin.eot');
  src: url('#{$roboto-font-path}roboto-thin.eot?#iefix') format('embedded-opentype'),
    url('#{$roboto-font-path}roboto-thin.woff2') format('woff2'), url('#{$roboto-font-path}roboto-thin.woff') format('woff'),
    url('#{$roboto-font-path}roboto-thin.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Roboto';
  src: local(Roboto Light), url('#{$roboto-font-path}roboto-light.eot');
  src: url('#{$roboto-font-path}roboto-light.eot?#iefix') format('embedded-opentype'),
    url('#{$roboto-font-path}roboto-light.woff2') format('woff2'), url('#{$roboto-font-path}roboto-light.woff') format('woff'),
    url('#{$roboto-font-path}roboto-light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: local(Roboto Regular), url('#{$roboto-font-path}roboto-regular.eot');
  src: url('#{$roboto-font-path}roboto-regular.eot?#iefix') format('embedded-opentype'),
    url('#{$roboto-font-path}roboto-regular.woff2') format('woff2'), url('#{$roboto-font-path}roboto-regular.woff') format('woff'),
    url('#{$roboto-font-path}roboto-regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$roboto-font-path}roboto-medium.eot');
  src: url('#{$roboto-font-path}roboto-medium.eot?#iefix') format('embedded-opentype'),
    url('#{$roboto-font-path}roboto-medium.woff2') format('woff2'), url('#{$roboto-font-path}roboto-medium.woff') format('woff'),
    url('#{$roboto-font-path}roboto-medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$roboto-font-path}roboto-bold.eot');
  src: url('#{$roboto-font-path}roboto-bold.eot?#iefix') format('embedded-opentype'),
    url('#{$roboto-font-path}roboto-bold.woff2') format('woff2'), url('#{$roboto-font-path}roboto-bold.woff') format('woff'),
    url('#{$roboto-font-path}roboto-bold.ttf') format('truetype');
  font-weight: 700;
}
