@import 'variables';
@import 'colors';

/** Typography **/
@mixin section-title {
  font-size: $font-size-h2;
  font-weight: 300;
  color: $text-color;
}

@mixin production-order-number {
  font-weight: 500;
  font-size: $font-size-h4;
  color: $primary-5;
  letter-spacing: 0.5px;
  line-height: 20px;
}

@mixin button-caption {
  font-size: $font-size-h4;
  color: $gray-11;
}
