/* You can add global styles to this file, and also import other style files */

@import 'assets/scss/roboto';
@import 'assets/bootstrap/bootstrap-grid.min.css';
@import 'assets/scss/global';
@import 'assets/icons/style.css';
@import 'assets/scss/icons';
@import 'assets/scss/buttons';
@import 'assets/scss/dialogs';
@import 'assets/scss/table';
