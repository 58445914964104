@import 'global-styles';

$dialog-header-padding: 15px;
$dialog-content-padding: 15px;
$dialog-header-background: $gray-1;
$dialog-max-height: 720px;

.chronos-dialog {
  font-family: $roboto-family;

  &--size {
    &-xs {
      width: 450px;
      max-height: $dialog-max-height;
    }
    &-sm {
      width: 800px;
      max-height: $dialog-max-height;
    }
    &-md {
      width: 1000px;
      max-height: $dialog-max-height;
    }
    &-lg {
      width: 1400px;
      max-height: $dialog-max-height;
    }
    &-xl {
      width: 1600px;
      max-height: $dialog-max-height;
    }
  }
}

// ******************************************
// Dialog from PrimeNG
// ******************************************

.p-dialog .p-dialog-header {
  background: $dialog-header-background;
  border-bottom: none;
  padding: $dialog-header-padding;
}

.p-dialog .p-dialog-content {
  padding: $dialog-content-padding;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: $font-weight-light;
  font-size: $font-size-h1;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none;
}
