/*** Default variables ***/
$default-logo-height: 56px;
$footer-height: 65px;
$default-radius: 5px;
$default-horizontal-padding: 0 5px;
$default-vertical-padding: 5px 0;
$default-padding: 5px;
$default-margin: 5px;
$default-border: 5px;
$doubled-default-padding: $default-padding * 2;
$doubled-default-margin: $default-margin * 2;

//*** z-axis indexes ***//
$z-index-header: 999;
$z-index-content: 100;

/*** Grid ***/

$grid-gutter: 30px;
$grid-gutter-half: calc(#{$grid-gutter} / 2);
$grid-gutter-third: calc(#{$grid-gutter} / 3);
$grid-gutter-quart: calc(#{$grid-gutter} / 4);
$grid-gutter-quarter: $grid-gutter-quart;
$grid-gutter-sixth: calc(#{$grid-gutter} / 6);

/*** Fonts ***/
$roboto-font-path: '/assets/fonts/roboto/';
$roboto-family: 'Roboto', 'Segoe UI', sans-serif;

$font-size-lg: 18px !default;
$font-size-md: 16px !default;
$font-size-base: 14px !default;
$font-size-sm: 12px !default;
$font-size-xs: 10px !default;

$font-size-h1: 24px !default;
$font-size-h2: 20px !default;
$font-size-h3: 18px !default;
$font-size-h4: 16px !default;
$font-size-h5: 14px !default;
$font-size-h6: 13px !default;

$font-size-display: 56px !default;
$font-size-display-lg: 40px !default;
$font-size-display-md: 36px !default;
$font-size-display-sm: 32px !default;

$font-size-icon-default: 20px !default;

$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

/*** Header ***/
$header-height: calc(#{$default-logo-height} + #{$grid-gutter-half} + 2px /* header border */);
$phase-header-height: 109px;
$run-phase-header-height: 73px;
$finish-phase-header-height: 80px;

/*** Buttons ***/
$order-item-button-width: 100px;

/*** Tracebility table  ***/
$result-select-button-wrapper-width: 180px;
$info-field-width: 100%;
$expanded-component-section-height: 40px;
$expanded-component-description-section-left-side-gutter: 34px;
$table-parameters-margin-gutter: 2px;
$table-parameters-border: white solid 1px;
$table-check-all-ok-field-color: #f5f5f5;
$table-check-element-background-color: #e6e6e6;