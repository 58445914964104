@import 'global-styles';

// ******************************************
// Dark Select Button modifying PrimeNG
// ******************************************

.chronos-select-button-dark.p-selectbutton .p-button {
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  border-color: $gray-4;
  background: $gray-3;

  &.p-highlight {
    background: $blue-8;
    border-color: $gray-10;
    color: $gray-1;
  }

  &:focus {
    box-shadow: none;
  }
}
