@import 'variables';

// ******************************************
// TreeTable from PrimeNG
// ******************************************
.p-treetable {
  .p-treetable-thead > tr,
  .p-treetable-tbody > tr {
    height: 50px;

    th,
    td {
      border-left: 0;
      border-right: 0;
      color: $gray-12;
    }
  }

  .p-treetable-thead > tr {
    font-size: $font-size-md;

    th {
      font-weight: 600;
      background-color: $gray-1;
      border-top: 0;
    }
  }

  .p-treetable-tbody > tr {
    td {
      font-weight: $font-weight-regular;
    }

    &.first-level-row {
      background-color: $gray-1;
    }

    &.second-level-row {
      background-color: $gray-3;
      td {
        border-bottom: 1px solid $gray-1;
      }
    }

    &.third-level-row {
      background-color: $gray-4;
      td {
        border-bottom: 1px solid $gray-1;
      }
    }

    &.fourth-level-row {
      background-color: $gray-5;
      td {
        border-bottom: 1px solid $gray-1;
      }
    }
  }
}
